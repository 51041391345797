import React from 'react';
import { Box, Image } from 'grommet';
import { Button } from '@appkit4/react-components/button';
import { navigate } from 'gatsby'
import SidebarDSA from '../../components/SidebarDSA';

export default function PersonaDSA() {
  const onClickButton_Robocaller = () => {
    navigate ('./dsa-info-governance')
  }
  const onClickButton_Explore = () => {
    navigate ('./dsa-explore')
  } 
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'100vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarDSA />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/BDgSmRTZwyglPP5vLBFa4/3b69426152192303a199f4b1fcd40360/dis-pe-dsa-my-learning.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} useMap="#discover-new" />
            <Button style={{width: '80px', position: 'absolute', top: '8px', left: '32%'}} kind='text' onClick={onClickButton_Explore} >&nbsp; &nbsp;<div className='beacon'></div></Button>
            <Button style={{width: '100px', position: 'absolute', top: '25vw', left: '7%'}} kind='text' onClick={onClickButton_Robocaller} >&nbsp; &nbsp;<div className='beacon'></div></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}