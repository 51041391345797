import React from 'react';
import { navigate } from 'gatsby'
import { Box, Card, CardHeader, CardBody, Heading,  Image, Paragraph } from 'grommet';

export default function SidebarDSA() {

  const onClickButton_DSAHome = () => {
    navigate ('/data-security-analyst')
  }
  return (
    <Box pad={{top: '10px'}} direction="column" alignContent="between" gap="medium" style={{maxWidth: '320px', width: '100%', height: 'max-content'}}>
          <Card background="light-1" style={{borderRadius: '.5em', overflow: 'visible', boxShadow: 'none'}}>
          <CardHeader gap="small">
            <Box style={{border: '4px solid #F8F8F8', borderRadius: '50%', margin: '-30px 0 0 20px', maxWidth: '110px', width: '100%'}}><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/6uXVBIYHUOU53luqDWkN5J/3ed93627b8e2ceac877b4899a75574f1/pe-persona-analyst-avatar.jpg" style={{borderRadius: '50%'}} onClick={onClickButton_DSAHome} /></Box>
            <Heading level={4} style={{margin: '10px 10px 0 0'}}>Data Security Analyst</Heading>
            </CardHeader>
            <CardBody pad="medium">
              <Paragraph style={{fontSize: '15px', lineHeight: '1.4', marginBottom: '20px'}}>The Data Security Analyst works with internal data owners to perform security audits, risk assessments and analysis, and to formulate security policies and procedures. </Paragraph>
              <Heading level={4} style={{marginBottom: '10px'}}>Challenges</Heading>
              <ul style={{fontSize: '15px', lineHeight: '1.5', marginBottom: '20px', padding: '0 0 0 15px'}}>
                <li>Understanding how to assess data criticality against business and regulatory requirements</li>
                <li>Staying abreast of current and emerging cyber threats and vulnerabilities</li>
                <li>Keeping current on new and changing industry-accepted frameworks and data security regulations</li>
              </ul>
            </CardBody>
          </Card>
          <Paragraph style={{fontSize: '18px', lineHeight: '1.5', fontWeight: '700', padding: '0 20px'}}>To learn more, reach out to your PwC Sponsor.</Paragraph>
        </Box>
  );
}